import { CountryType, SupportedLocaleTag } from '@checkout-ui/shared-domain-entities';

import { COUNTRY_SUPPORTED_LANGUAGES_FOR_UI } from './constants/defaultLocaleValue';

const ALL_SUPPORTED_LOCALE_TAGS = Object.values(SupportedLocaleTag);

export const getAvailableLocalesList = (
  countryCode: CountryType | undefined | null,
  fallback: SupportedLocaleTag[] = ALL_SUPPORTED_LOCALE_TAGS,
): SupportedLocaleTag[] => {
  if (!countryCode) {
    return fallback;
  }

  const countryConfig = COUNTRY_SUPPORTED_LANGUAGES_FOR_UI[countryCode];

  if (!countryConfig) {
    return fallback;
  }

  return countryConfig.options.map((option) => option.value);
};
