import {
  isDev,
  isPaellaSandboxEnv,
  isProd,
  isTestInstance,
} from '@checkout-ui/shared/utils';
import SentryFullStory from '@sentry/fullstory';

import { SentryEnv, SentryOptions } from './types';

//TODO:move hardcoded keys to env variables when infra allows us to provide build time env variables
const DSN = isDev()
  ? ''
  : 'https://18a36932afae44ceba6a4d267fe1c96b@o60503.ingest.sentry.io/6252940';
const SENTRY_ORG_SLUG = 'billie';

export const getEnv = () => {
  if (isDev()) {
    return SentryEnv.dev;
  }
  if (isProd()) {
    return SentryEnv.prod;
  }

  return SentryEnv.stg; // test instances and paella-sandbox
};

export const getSentryOptions: () => SentryOptions = () => {
  const options: SentryOptions = {
    dsn: DSN,
    environment: getEnv(),
    integrations: [new SentryFullStory(SENTRY_ORG_SLUG)],

    // tracing config docs: https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#tracing-options
    sampleRate: 0.2,
    tracesSampleRate: isProd() ? 0.2 : 0,

    debug: isProd() ? false : true,

    beforeSend: (event) => {
      if (isDev() || typeof window === 'undefined') {
        return null; // do not send events for local dev or on server
      }
      return event;
    },
  };

  return options;
};

export const getNewRelicOptions = (applicationID: string) => ({
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: 'NRBR-57fcb85feb34fac35eb',
    sa: 1,
    applicationID,
  },
  loader_config: {
    accountID: '1612462',
    trustKey: '1612462',
    agentID: applicationID,
    licenseKey: 'NRBR-57fcb85feb34fac35eb',
    applicationID,
  },
});

export const getNewrelicAppIdForEnv = ({
  prodId,
  paellaSandboxId,
}: {
  prodId: string;
  paellaSandboxId: string;
}) => {
  if (isDev() || isTestInstance()) {
    return;
  }

  if (isPaellaSandboxEnv()) {
    return paellaSandboxId;
  }

  return prodId;
};
