import { CountryType, SupportedLanguage } from '@checkout-ui/shared-domain-entities';

import { COUNTRY_LANGUAGE_LOCALE } from './constants/defaultLocaleValue';

export const isSupportedLanguage = (
  countryCode: CountryType,
  languageCode: string,
): languageCode is SupportedLanguage => {
  const countryConfig = COUNTRY_LANGUAGE_LOCALE[countryCode];

  if (!countryConfig) {
    return false;
  }

  const languages = Object.keys(countryConfig);

  return languages.includes(languageCode);
};
