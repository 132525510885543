import { DebtorCompany } from './debtor-company';

export enum AuthorizationResults { //TODO: refactor to union types instead
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  USER_ACTION_REQUIRED = 'user_action_required',
}

//TODO: refactor to union types instead
export enum AllDeclinedReasons {
  DEBTOR_ADDRESS = 'debtor_address',
  DEBTOR_LIMIT_EXCEEDED = 'debtor_limit_exceeded',
  DEBTOR_NOT_IDENTIFIED = 'debtor_not_identified',
  RISK_POLICY = 'risk_policy',
  RISK_SCORING_FAILED = 'risk_scoring_failed',
  TIMEOUT = 'timeout',

  PERSON_NOT_IDENTIFIED = 'person_not_identified',
  PERSONAL_NUMBER = 'personal_number_required',

  //TEMP for bankid transition phase
  PERSON_NOT_IDENTIFIED_OLD = 'person_not_identified_old',
  PERSONAL_NUMBER_OLD = 'personal_number_required_old',

  BUYER_CONFIRMATION = 'buyer_confirmation',
  //Frontend decline reasons
  EMAIL_OTP_REQUIRED = 'email_otp_required',
  PERSONAL_DATA = 'frontend_only::personal_data',
}

type ResultUserActionRequired = AuthorizationResults.USER_ACTION_REQUIRED;

type ResultRejected = AuthorizationResults.REJECTED;

type ResultAccepted = AuthorizationResults.ACCEPTED;

type UserActionRequiredDeclineReasons =
  | AllDeclinedReasons.DEBTOR_ADDRESS
  | AllDeclinedReasons.DEBTOR_NOT_IDENTIFIED
  | AllDeclinedReasons.PERSONAL_NUMBER
  | AllDeclinedReasons.PERSONAL_NUMBER_OLD
  | AllDeclinedReasons.BUYER_CONFIRMATION
  | AllDeclinedReasons.EMAIL_OTP_REQUIRED
  | AllDeclinedReasons.PERSONAL_DATA;

export type RejectedDeclineReasons =
  | AllDeclinedReasons.RISK_POLICY
  | AllDeclinedReasons.RISK_SCORING_FAILED
  | AllDeclinedReasons.DEBTOR_LIMIT_EXCEEDED
  | AllDeclinedReasons.PERSON_NOT_IDENTIFIED
  | AllDeclinedReasons.PERSON_NOT_IDENTIFIED_OLD;

type DeclineReasons = UserActionRequiredDeclineReasons | RejectedDeclineReasons;

type AuthorizationTimeout = AllDeclinedReasons.TIMEOUT;

export type AuthorizationEvaluation = {
  result: ResultUserActionRequired | ResultAccepted | ResultRejected;
  decline_reason?: DeclineReasons | AuthorizationTimeout;
  suggested_debtor?: DebtorCompany;
};
