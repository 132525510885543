import { logger } from '@checkout-ui/shared-logger';
import { useCallback, useMemo } from 'react';

import { IdentifyParams, MetaData, TrackEventType } from './types';

const metaDataVars: MetaData = {
  integration_type: undefined,
  locale: undefined,
  country: undefined,
};

function persistInternalValues({
  integration_type,
  locale,
  country,
}: MetaData) {
  if (integration_type) {
    metaDataVars.integration_type = integration_type;
  }

  if (locale) {
    metaDataVars.locale = locale;
  }

  if (country) {
    metaDataVars.country = country;
  }
}

export function useAnalytics() {
  const setMetaData = useCallback(
    ({ locale, country, integration_type }: MetaData) => {
      if (!window) return;
      const { analytics } = window;

      const metadata = {
        integration_type,
        locale,
        country,
      };

      persistInternalValues(metadata);

      if (!analytics) {
        return logger.info(
          'set metadata:',
          metadata,
        );
      }

      analytics.identify(metadata);
    },
    []
  );

  const identify = useCallback((...args: IdentifyParams) => {
    if (!window) return;

    const analytics = window.analytics;
    const [userId, traits, ...rest] = args;

    const metaData = {
      locale: metaDataVars.locale,
      country: metaDataVars.country,
      integration_type: metaDataVars.integration_type,
    };

    if (!analytics) {
      return logger.info(
        'analytics identify:',
        userId,
        { ...traits, ...metaData },
        ...rest
      );
    }

    analytics.identify(userId, { ...traits, ...metaData }, ...rest);
  }, []);

  const trackEvent: TrackEventType = useCallback((...args) => {
    if (!window) return;
    const analytics = window.analytics;
    const [eventName, properties, ...rest] = args;

    const metaData = {
      locale: metaDataVars.locale,
      country: metaDataVars.country,
      integration_type: metaDataVars.integration_type,
    };

    if (!analytics) {
      return logger.info(
        'analytics trackEvent:',
        eventName,
        { ...properties, ...metaData },
        ...rest
      );
    }

    return analytics.track(
      eventName,
      {
        ...properties,
        ...metaData,
      },
      ...rest
    );
  }, []);
  return useMemo(
    () => ({ trackEvent, identify, setMetaData }),
    [trackEvent, identify, setMetaData]
  );
}

export default useAnalytics;
