import { BodyText } from '@checkout-ui/design-system/BodyText';
import { Selectv2 } from '@checkout-ui/design-system/Selectv2/Selectv2';
import { Text } from '@checkout-ui/design-system/Text';
import { useAnalytics } from '@checkout-ui/shared-analytics';
import {
  COUNTRY_SUPPORTED_LANGUAGES_FOR_UI,
  FormattedMessage,
  getLocalLanguageName,
} from '@checkout-ui/shared-context-locale';
import { useSelect } from 'downshift';
import { useEffect, useMemo } from 'react';

import { useConfigurationPanel } from './context/ConfigurationPanelContext';

export const LanguageSelector = () => {
  const { config, handleConfigChange } = useConfigurationPanel();
  const { trackEvent } = useAnalytics();

  const countryConfig = COUNTRY_SUPPORTED_LANGUAGES_FOR_UI[config.country];
  const languages = countryConfig.options;

  const defaultLanguageOption = useMemo(() => {
    return (languages.find((option) => option.value === countryConfig.defaultValue))!;
  }, [countryConfig.defaultValue, languages]);

  const countryDerivedSelectedItem = useMemo(() => {
    return languages
      .find((lang) =>
        lang.value === config.language
      ) || defaultLanguageOption;
  }, [languages, config.language, defaultLanguageOption]);

  useEffect(() => {
    if (countryDerivedSelectedItem.value !== config.language) {
      handleConfigChange(countryDerivedSelectedItem, 'language');
    }
  }, [handleConfigChange, countryDerivedSelectedItem, config.language]);

  const {
    selectedItem,
    highlightedIndex,
    getItemProps,
    isOpen,
    getMenuProps,
    getToggleButtonProps,
    getLabelProps,
  } = useSelect({
    items: languages,
    selectedItem: countryDerivedSelectedItem,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      handleConfigChange(newSelectedItem || null, 'language');
      trackEvent(`${newSelectedItem?.value} language selected`);
    },
  });

  const languageSelectOptions = languages.map((item, index) => {
    return (
      <Selectv2.Option
        key={item.value}
        value={item.value}
        isSelected={
          selectedItem
          ? selectedItem.value === item.value
          : false
        }
        isHighlighted={highlightedIndex === index}
        dataTest={`language__option--${item.value}`}
        {...getItemProps({
          item,
          index,
        })}
      >
        <BodyText>{getLocalLanguageName(item.displayNameCode)}</BodyText>
      </Selectv2.Option>
    );
  });

  return (
    <Selectv2
      isOpen={isOpen}
      value={
        selectedItem ? <Text>{getLocalLanguageName(selectedItem.displayNameCode)}</Text> : undefined
      }
      menuProps={getMenuProps()}
      triggerProps={getToggleButtonProps()}
      labelProps={getLabelProps()}
      data-test="language"
      placeholder={
        <Text>
          <FormattedMessage id="demo-shop.configuration.language-placeholder" />
        </Text>
      }
      // FIXME: a possible issue with the UI library, check the reason why we need to override styles
      //  see PURCHASE-841 for details
      label={
        <BodyText tag="span">
          <FormattedMessage id="demo-shop.configuration.language" />
        </BodyText>
      }
    >
      {languageSelectOptions}
    </Selectv2>
  );
};
