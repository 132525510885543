import {
  CountryType,
  SupportedLanguage,
  SupportedLocaleTag,
} from '@checkout-ui/shared-domain-entities';

import { COUNTRY_LANGUAGE_LOCALE } from './constants/defaultLocaleValue';

export const getLocaleTag = (
  countryCode: CountryType,
  languageCode: SupportedLanguage,
  defaultLocaleTag: SupportedLocaleTag = SupportedLocaleTag.German,
): SupportedLocaleTag => {
  const countryLocales = COUNTRY_LANGUAGE_LOCALE[countryCode];

  if (!countryLocales) {
    return defaultLocaleTag;
  }

  const locale = countryLocales[languageCode];

  if (!locale) {
    return defaultLocaleTag;
  }

  return locale;
};
